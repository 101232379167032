<template>
  <div>
    <div class="lg:hidden">
      <div class="py-2 flex flex-col flex-grow lg:mt-32 lg:min-h-50vh">
    <BPageLoader v-if="isLoading || msgSubjects.isLoading" class="flex-grow" />

    <ReplyMessage
      v-else-if="isReply"
      :subjectId="subjectId"
      :messageId="messageId"
      @prev="isReply = false"
      @update="handleUpdate"
    />

    <template v-else>
      <header class="pl-6 py-2 text-lg flex font-semibold text-text-alternate-1 pr-12">
        <button @click="$router.go(-1)">
          <LeftArrowIcon class="h-6 fill-current" />
        </button>
        <h2 class="mx-auto truncate">
          {{ msgSubjects.data.subjects[subjectId].description }}
        </h2>
      </header>

      <main class="my-4 flex-grow flex flex-col px-6">
        <ul class="mb-8 flex flex-col gap-7">
          <template v-for="msg in data[subjectId]" :key="msg.createdAt">
            <li :class="['flex', { 'ml-auto': msg.sender === 2 }]">
              <div :class="['text-2xs']" :style="{ order: msg.recepient }">
                <div
                  :class="[
                    'p-4 rounded-t-lg text-text-alternate-4 leading-5',
                    msg.sender === 2
                      ? 'rounded-l-lg bg-bg-alternate-6'
                      : 'rounded-r-lg bg-bg-alternate-3',
                  ]"
                >
                  {{ msg.message }}
                </div>
                <p class="flex gap-2 font-semibold mt-2">
                  <span class="text-text-alternate-3">
                    {{ dayjs(msg.createdAt).format('h:mma') }}
                  </span>
                  <span class="text-text-alternate-3">
                    {{ dayjs(msg.createdAt).format('DD MMM YYYY') }}
                  </span>
                </p>
              </div>
            </li>
          </template>
        </ul>
        <button
          class="
            focus:outline-none
            bg-btn-bg-primary
            text-btn-text-primary
            rounded
            sticky
            bottom-16
            font-bold
            text-xs
            py-2
            px-6
            flex
            items-center
            gap-2
            ml-auto
            mt-auto
          "
          @click="isReply = true"
        >
          <ReplyIcon class="h-4 fill-current" />
          Reply
        </button>
      </main>
    </template>
  </div>
    </div>
    <div class="hidden lg:flex justify-center pt-4">
      <div class="b-card mt-24 pt-8 px-26">
        <div class="py-2 flex flex-col flex-grow ">
    <BPageLoader v-if="isLoading || msgSubjects.isLoading" class="flex-grow" />

    <ReplyMessage
      v-else-if="isReply"
      :subjectId="subjectId"
      :messageId="messageId"
      @prev="isReply = false"
      @update="handleUpdate"
    />

    <template v-else>
      <header class="pl-6 py-2 text-lg flex font-semibold text-text-alternate-1 pr-12">
        <button @click="$router.go(-1)">
          <LeftArrowIcon class="h-6 fill-current" />
        </button>
        <h2 class="mx-auto truncate">
          {{ msgSubjects.data.subjects[subjectId].description }}
        </h2>
      </header>

      <main class="my-4 flex-grow flex flex-col px-6">
        <ul class="mb-8 flex flex-col gap-7">
          <template v-for="msg in data[subjectId]" :key="msg.createdAt">
            <li :class="['flex', { 'ml-auto': msg.sender === 2 }]">
              <div :class="['text-2xs']" :style="{ order: msg.recepient }">
                <div
                  :class="[
                    'p-4 rounded-t-lg text-text-alternate-4 leading-5',
                    msg.sender === 2
                      ? 'rounded-l-lg bg-bg-alternate-6'
                      : 'rounded-r-lg bg-bg-alternate-3',
                  ]"
                >
                  {{ msg.message }}
                </div>
                <p class="flex gap-2 font-semibold mt-2">
                  <span class="text-text-alternate-3">
                    {{ dayjs(msg.createdAt).format('h:mma') }}
                  </span>
                  <span class="text-text-alternate-3">
                    {{ dayjs(msg.createdAt).format('DD MMM YYYY') }}
                  </span>
                </p>
              </div>
            </li>
          </template>
        </ul>
        <button
          class="
            focus:outline-none
            bg-btn-bg-primary
            text-btn-text-primary
            rounded
            sticky
            bottom-16
            font-bold
            text-xs
            py-2
            px-6
            flex
            items-center
            gap-2
            ml-auto
            mt-auto
          "
          @click="isReply = true"
        >
          <ReplyIcon class="h-4 fill-current" />
          Reply
        </button>
      </main>
    </template>
  </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, toRefs, ref } from 'vue';
import dayjs from 'dayjs';
import { useApi } from '@/cmp-functions/useApi';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { getMessageThread } from '@/services/api';
import { useAppState } from '@/cmp-functions/appState.js';
import ReplyMessage from './ReplyMessage';

export default {
  name: 'MessageChat',

  components: { ReplyMessage },

  setup() {
    const store = useStore();
    const route = useRoute();
    const isReply = ref(false);

    const authUser = computed(() => store.state.auth.authData);
    const [response, fetchMessages] = useApi(getMessageThread);
    const messageId = computed(() => route.params.id);

    fetchMessages({ userId: authUser.value.id, messageId: messageId.value });

    const { msgSubjects } = useAppState();

    const subjectId = computed(() => Object.keys(response.data || {})[0]);

    const handleUpdate = (msg) => {
      const createdAt = Date.now();
      Object.assign(response.data[subjectId.value], {
        [createdAt]: { ...msg, createdAt },
      });
      isReply.value = false;
    };

    return {
      ...toRefs(response),
      handleUpdate,
      messageId,
      subjectId,
      msgSubjects,
      dayjs,
      isReply,
    };
  },
};
</script>

<style scoped>
.shadow {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}
</style>
