<template>
  <div class="px-6 flex flex-col font-semibold flex-grow ">
    <header class="py-2 text-lg flex text-text-alternate-1 pr-6">
      <button class="focus:outline-none" @click="handlePrev">
        <LeftArrowIcon class="h-6 fill-current" />
      </button>
      <h2 class="mx-auto">Reply Message</h2>
    </header>

    <main class="my-6 flex flex-col text-sm">
      <div class="flex-grow">
        <div class="flex flex-col my-3">
          <label class="text-text-primary font-semibold">Subject</label>
          <input
            class="
              border
              rounded
              border-border-primary
              my-2
              p-3.5
              text-sm
              font-medium
              focus:outline-none
            "
            :value="`Re:${msgSubjects.data.subjects[subjectId].description}`"
            disabled
          />
        </div>
        <div class="flex flex-col my-3">
          <label class="text-text-primary font-semibold">Message</label>
          <textarea
            class="
              border
              rounded
              border-border-primary
              my-2
              p-3.5
              text-sm
              font-medium
              focus:outline-none
            "
            v-model="description"
            required
          />
        </div>
      </div>
      <div class="text-center">
        <button
          class="
            bg-btn-bg-primary
            text-btn-text-primary text-sm
            rounded
            w-full
            my-4
            py-4
            font-bold
          "
          type="submit"
          @click="handleSubmit"
          :disabled="!description || isLoading"
        >
          <BButtonLoader class="h-4 w-4 mx-auto" v-if="isLoading" />
          <span v-else>Send Message</span>
        </button>
      </div>
    </main>
  </div>
</template>

<script>
import { ref, toRefs, watch } from 'vue';
import { useApi } from '@/cmp-functions/useApi';
import { postMessageReply } from '@/services/api';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { useAppState } from '@/cmp-functions/appState.js';

export default {
  name: 'ReplyMessage',

  props: ['subjectId', 'messageId'],

  emits: ['prev', 'update'],

  setup(props, { emit }) {
    const description = ref('');
    const store = useStore();
    const authUser = store.state.auth.authData;
    const toast = useToast();

    const [response, sendMessage] = useApi(postMessageReply);

    watch(response, ({ isSuccess }) => {
      if (isSuccess) {
        toast.success('Sent!');
      }
    });

    const handlePrev = () => {
      if (!response.isSuccess) {
        emit('prev');
      } else {
        const payload = {
          message: description.value,
          sender: 2,
          recepient: 1,
        };
        emit('update', payload);
      }
    };

    const handleSubmit = () => {
      if (description.value) {
        const payload = {
          message: description.value,
          messageId: props.messageId,
          subjectId: props.subjectId,
          userId: authUser.id,
          sender: 2,
          recepient: 1,
        };

        sendMessage(payload);
      }
    };
    const { msgSubjects } = useAppState();

    return {
      ...toRefs(response),
      description,
      handleSubmit,
      msgSubjects,
      handlePrev,
    };
  },
};
</script>
